document.addEventListener("DOMContentLoaded", () => {
  const emailElements = document.querySelectorAll(".scrambled-eggs");

  emailElements.forEach((emailElement) => {
    // Reverse the current content
    let address = emailElement.textContent.split("").reverse().join("").trim();

    // Remove the CSS class for reversing
    emailElement.classList.remove("scrambled-eggs");

    // Build mailto-link from address if it's a valid email
    if (address.includes("@")) {
      emailElement.innerHTML = `<a href='mailto:${address}'>${address}</a>`;
    }
  });
});
